<template>
  <div style="background: linear-gradient(to bottom, #FFFFFF 50%, #FFC431 50%);" class="pa-8">
    <div class="container-card pa-14 pt-16" style="display: flex; align-items: center; justify-content: center;">
        <video  style="width: 100%;" autoplay loop muted controls>
          <source src="https://proplat-public-images.s3.amazonaws.com/home2.mp4#t=5" type="video/mp4">
            Tu navegador no soporta el elemento de video.
        </video>
      </div>
  </div>
</template>

<script>
export default {
  name: "HomeCatalogoTypeLayout",
  data() {
    return {
      //VARIABLES
      isPlaying: false
    };
  },
  beforeMount() {
  },
  methods: {
    togglePlay() {
      const video = this.$refs.video;
      if (this.isPlaying) {
        video.pause();
      } else {
        video.play();
      }
      this.isPlaying = !this.isPlaying;
    }
  },
  computed: {
    selectLanguage: function () {
      return this.$store.state.language;
    },
  },
  watch: {
    selectLanguage: function () {
      if (this.selectLanguage) {
      }
    },
  },
};
</script>

<style scoped>
.container-card {
  background-color: rgb(255, 196, 49);
  -webkit-border-radius: 75px 75px 0 0; /* Prefijo de vendedor para navegadores Webkit como Chrome y Safari */
  border-radius: 75px 75px 0 0;
}
.container-card-gradient {
  min-width: 250px;
  background: transparent linear-gradient(243deg, #ff8181 0%, #ffa77e 100%) 0%
    0% no-repeat padding-box;
  border-radius: 11px;
  opacity: 1;
  padding: 10px 0px;
  margin-right: 15px;
}

.scrolled-lateral {
  width: 100%;
  display: flex;
  overflow-x: auto;
}

.txt-title {
  text-align: left;
  letter-spacing: 0px;
  color: #3d414a;
  opacity: 1;
  font-size: 18px;
  font-weight: 600;
}

.txt-title-card {
  text-align: center;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  margin-bottom: 10px;
  font-size: 18px;
}

.button-card {
  width: 80%;
  border: 1px solid #ffffff;
  border-radius: 13px;
  opacity: 1;
  text-align: center;
  letter-spacing: 0px;
  color: #ffffff !important;
  opacity: 1;
  font-size: 10px !important;
  background-color: transparent !important;
  text-transform: initial;
}

@media (max-width: 960px) {
  .hidden-from-tablet {
    display: none;
  }
}
</style>